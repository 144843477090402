<template>
    <b-card title="Add User">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- First Name -->
                    <b-col cols="12">
                        <b-form-group label="First Name" label-for="firstname">
                            <validation-provider name="First Name" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="firstname" v-model="firstname"
                                        :state="errors.length > 0 ? false : null" placeholder="First Name" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Last Name" label-for="lastname">
                            <validation-provider name="Last Name" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="lastname" v-model="lastname" :state="errors.length > 0 ? false : null"
                                        placeholder="Last Name" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- email -->
                    <b-col cols="12">
                        <b-form-group label="Email" label-for="fh-email">
                            <validation-provider name="Email" rules="required|max:50|email|unique" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MailIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="fh-email" v-model="email" type="email"
                                        :state="errors.length > 0 ? false : null" placeholder="Email" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-form-text class="text-danger">
                                Enter a Valid Email
                            </b-form-text>
                        </b-form-group>
                    </b-col>

                    <!-- password -->
                    <b-col cols="12">
                        <b-form-group label="Password" label-for="fh-password">
                            <validation-provider name="Password" rules="required|password|min:5|max:20"
                                #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="LockIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="fh-password" v-model="password" type="password"
                                        :state="errors.length > 0 ? false : null" placeholder="Password" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Profile Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewImg" :class="previewImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Profile Image -->
                    <b-col cols="12">
                        <b-form-group label="Profile Image" label-for="profile">
                            <validation-provider name="Profile Image" rules="required|image|size:100" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="profile" v-model="profileImg" ref="fileInput" @change="onImageChange"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Country -->
                    <b-col cols="12">
                        <b-form-group label="Select Country" label-for="country">
                            <validation-provider name="Country" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="country" v-model="selectedCountry" :options="countryOptions"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- User Role -->
                    <b-col cols="12">
                        <b-form-group label="Select User Role" label-for="role">
                            <validation-provider name="User Role" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="role" v-model="userStatus" :options="roleOptions"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="validationForm"
                            variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
    required, email, confirmed, password, image, size, unique
} from '@validations'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BImg,
        ValidationProvider,
        ValidationObserver,
        validate,
        required,
        email,
        confirmed,
        password,
        image,
        size,
        unique
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            previewImg: '',
            profileImg: '',
            userStatus: null,
            roleOptions: [
                { value: null, text: 'Select User Role' },
                { value: '1', text: 'Admin' },
                { value: '2', text: 'Normal User' },
            ],
            selectedCountry: null,

            countryOptions: [
                { value: null, text: 'Please select a country' },
            ]
        }
    },
    created() {
        this.getCountries();
    },
    computed: {
        validation() {
            return this.firstname.length > 4 && this.firstname.length < 13
        },
    },
    methods: {
        reset() {
            this.firstname = '',
                this.lastname = '',
                this.email = '',
                this.password = '',
                this.previewImg = '',
                this.profileImg = '',
                this.selectedCountry = null;
        },

        getCountries() {
            this.countries = [];

            db.collection("countries")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.countryOptions.push({
                            value: doc.id,
                            text: doc.data().country,
                            slug: doc.data().slug,
                        });
                        console.log(doc.id, " => ", doc.data());

                    });


                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        validationForm() {

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submit();
                }
            })
        },

        onImageChange(event) {

            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Profile Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        this.previewImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewImg = '';
                }
            });
        },

        submit() {
            store.dispatch('app/commitActivateLoader');
            firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
                .then(data => {


                    console.log(data.user)
                    data.user.updateProfile({
                        displayName: this.firstname,
                    })
                        .then(() => {
                            var date = new Date().toLocaleString();//.toISOString().slice(0, 10);



                            db.collection("users")
                                .add({
                                    user_id: data.user.uid,
                                    firstname: this.firstname,
                                    lastname: this.lastname,
                                    email: this.email,
                                    country: this.selectedCountry,
                                    password: this.password,
                                    created: date,
                                    modified: date,
                                    status: parseInt(this.userStatus),

                                })
                                .then((doc) => {
                                    console.log("Added Successfully!");
                                    const filename = this.profileImg.name;
                                    const ext = filename.slice(filename.lastIndexOf('.'));
                                    const storageRef = storage.ref();
                                    const fileRef = storageRef.child('users/' + doc.id + "." + ext);
                                    fileRef.put(this.profileImg).then(result => {
                                        fileRef.getDownloadURL().then(imageUrl => {
                                            console.log(imageUrl);
                                            db.collection("users").doc(doc.id).update({ image: imageUrl })
                                            store.dispatch('app/commitDeactivateLoader');
                                            this.$router.push({ name: 'users-list' });
                                            this.showNotification('User Added Successfully!', 'UserIcon', 'success');
                                        }).catch((error) => {
                                            console.error("Error writing document: ", error);
                                            this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                                        });
                                    });
                                }).then(() => {
                                    // const url = "https://introsbs.com/api/registrationthankyou";
                                    const url = this.$VUE_APP_API_ENDPOINT + "registrationthankyou";

                                    const data1 = {
                                        email: this.email,
                                        name: this.firstname,

                                    };

                                    const options = {
                                        method: 'POST',
                                        headers: { 'content-type': 'application/json' },
                                        data: JSON.stringify(data1),
                                        url: url
                                    };

                                    axios(options).then(
                                        (response) => {
                                            console.warn(Object.keys(response));
                                            if (response != "") {
                                                // this.$toasted.success('Success, Registration Successfully!', {
                                                //     icon: 'check',
                                                // })
                                                //this.$router.push("signin");
                                            }
                                        }, () => {
                                            this.isLoading = false;
                                            // this.$toasted.error('Error, Registration Failed!', {
                                            //     icon: 'check',
                                            //     //icon : 'error_outline'
                                            // })
                                        });
                                })
                                .catch((error) => {
                                    store.dispatch('app/commitDeactivateLoader');
                                    console.error("Error writing document: ", error);
                                    this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                                });
                            var id = localStorage.getItem("userSessionId");
                            console.log('rrr ' + id);
                            //this.$router.replace({ name: "Dashboard" });
                            //this.$router.replace({ name: "userProfile" });
                            //this.$router.push('/');

                        });
                }).catch(err => {
                    store.dispatch('app/commitDeactivateLoader');
                    console.log('sdd');
                    console.log(err.message);
                    this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                    // this.$toasted.error('Error,' + err.message, {
                    //     //icon : 'check',
                    //     icon: 'error_outline'
                    // })

                    this.error = err.message;
                });
            ///////////////////////////////////////////////////////////////////////////////


        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>